import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a06901f0 = () => interopDefault(import('../pages/aiApproximate/index.vue' /* webpackChunkName: "pages/aiApproximate/index" */))
const _c77a7d50 = () => interopDefault(import('../pages/batchSearchNew/index.vue' /* webpackChunkName: "pages/batchSearchNew/index" */))
const _0acd97b1 = () => interopDefault(import('../pages/bjtest.vue' /* webpackChunkName: "pages/bjtest" */))
const _9ce92dbc = () => interopDefault(import('../pages/brandProtect/index.vue' /* webpackChunkName: "pages/brandProtect/index" */))
const _70a84a94 = () => interopDefault(import('../pages/businessClues/index.vue' /* webpackChunkName: "pages/businessClues/index" */))
const _01550b17 = () => interopDefault(import('../pages/businessDevelopment/index.vue' /* webpackChunkName: "pages/businessDevelopment/index" */))
const _134ab2de = () => interopDefault(import('../pages/classify/index.vue' /* webpackChunkName: "pages/classify/index" */))
const _8f9ef500 = () => interopDefault(import('../pages/commonRevision/index.vue' /* webpackChunkName: "pages/commonRevision/index" */))
const _553603ca = () => interopDefault(import('../pages/customReports/index.vue' /* webpackChunkName: "pages/customReports/index" */))
const _9db15a78 = () => interopDefault(import('../pages/emailModel.vue' /* webpackChunkName: "pages/emailModel" */))
const _402d8010 = () => interopDefault(import('../pages/imgTrademarkSearch/index.vue' /* webpackChunkName: "pages/imgTrademarkSearch/index" */))
const _2ec19993 = () => interopDefault(import('../pages/ipBlockade.vue' /* webpackChunkName: "pages/ipBlockade" */))
const _58fe3272 = () => interopDefault(import('../pages/lawbook/index.vue' /* webpackChunkName: "pages/lawbook/index" */))
const _2509e165 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _753af83c = () => interopDefault(import('../pages/monitoring/index.vue' /* webpackChunkName: "pages/monitoring/index" */))
const _6591b7ec = () => interopDefault(import('../pages/myClues/index.vue' /* webpackChunkName: "pages/myClues/index" */))
const _4aa699f2 = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _f5111c36 = () => interopDefault(import('../pages/objection/index.vue' /* webpackChunkName: "pages/objection/index" */))
const _ac76d29c = () => interopDefault(import('../pages/popularize.vue' /* webpackChunkName: "pages/popularize" */))
const _41304cf2 = () => interopDefault(import('../pages/saveUcToken.vue' /* webpackChunkName: "pages/saveUcToken" */))
const _7d467016 = () => interopDefault(import('../pages/trademarkSearch/index.vue' /* webpackChunkName: "pages/trademarkSearch/index" */))
const _5d30b522 = () => interopDefault(import('../pages/trademarkSearchInternational/index.vue' /* webpackChunkName: "pages/trademarkSearchInternational/index" */))
const _fe719e80 = () => interopDefault(import('../pages/analysis/dataExtraction/index.vue' /* webpackChunkName: "pages/analysis/dataExtraction/index" */))
const _b52f6734 = () => interopDefault(import('../pages/analysis/extension/index.vue' /* webpackChunkName: "pages/analysis/extension/index" */))
const _733ad8ef = () => interopDefault(import('../pages/analysis/newBj/index.vue' /* webpackChunkName: "pages/analysis/newBj/index" */))
const _1e7f27aa = () => interopDefault(import('../pages/analysis/portrait/index.vue' /* webpackChunkName: "pages/analysis/portrait/index" */))
const _3ea5e67e = () => interopDefault(import('../pages/brandProtect/list.vue' /* webpackChunkName: "pages/brandProtect/list" */))
const _68a6f26a = () => interopDefault(import('../pages/businessClues/list.vue' /* webpackChunkName: "pages/businessClues/list" */))
const _01ca824c = () => interopDefault(import('../pages/classify/search.vue' /* webpackChunkName: "pages/classify/search" */))
const _4946ef02 = () => interopDefault(import('../pages/customReports/release.vue' /* webpackChunkName: "pages/customReports/release" */))
const _4e9d863a = () => interopDefault(import('../pages/lawbook/details/index.vue' /* webpackChunkName: "pages/lawbook/details/index" */))
const _3010fa50 = () => interopDefault(import('../pages/login/binding.vue' /* webpackChunkName: "pages/login/binding" */))
const _15a54da5 = () => interopDefault(import('../pages/login/forgetPassword.vue' /* webpackChunkName: "pages/login/forgetPassword" */))
const _34901387 = () => interopDefault(import('../pages/login/logout.vue' /* webpackChunkName: "pages/login/logout" */))
const _7b8d1a40 = () => interopDefault(import('../pages/login/register.vue' /* webpackChunkName: "pages/login/register" */))
const _6c8b9752 = () => interopDefault(import('../pages/monitoring/details.vue' /* webpackChunkName: "pages/monitoring/details" */))
const _cf5ae2d6 = () => interopDefault(import('../pages/noticecomm/searchNumber.vue' /* webpackChunkName: "pages/noticecomm/searchNumber" */))
const _32de1358 = () => interopDefault(import('../pages/objection/details/index.vue' /* webpackChunkName: "pages/objection/details/index" */))
const _4ec41f3a = () => interopDefault(import('../pages/special/area.vue' /* webpackChunkName: "pages/special/area" */))
const _32b8b69f = () => interopDefault(import('../pages/special/geography.vue' /* webpackChunkName: "pages/special/geography" */))
const _267db50e = () => interopDefault(import('../pages/special/modality.vue' /* webpackChunkName: "pages/special/modality" */))
const _002cfa58 = () => interopDefault(import('../pages/special/trademark.vue' /* webpackChunkName: "pages/special/trademark" */))
const _530522a5 = () => interopDefault(import('../pages/trademarkSearch/details.vue' /* webpackChunkName: "pages/trademarkSearch/details" */))
const _9b31b0d6 = () => interopDefault(import('../pages/trademarkSearch/tourist.vue' /* webpackChunkName: "pages/trademarkSearch/tourist" */))
const _20039b5c = () => interopDefault(import('../pages/trademarkSearchInternational/data.js' /* webpackChunkName: "pages/trademarkSearchInternational/data" */))
const _e862ecc2 = () => interopDefault(import('../pages/trademarkSearchInternational/details.vue' /* webpackChunkName: "pages/trademarkSearchInternational/details" */))
const _cd0fd114 = () => interopDefault(import('../pages/user/exportManage.vue' /* webpackChunkName: "pages/user/exportManage" */))
const _b7b27f56 = () => interopDefault(import('../pages/user/notice.vue' /* webpackChunkName: "pages/user/notice" */))
const _96b06146 = () => interopDefault(import('../pages/user/noticeDetails.vue' /* webpackChunkName: "pages/user/noticeDetails" */))
const _5346e2d6 = () => interopDefault(import('../pages/user/userInfo.vue' /* webpackChunkName: "pages/user/userInfo" */))
const _1de32dcc = () => interopDefault(import('../pages/analysis/portrait/details.vue' /* webpackChunkName: "pages/analysis/portrait/details" */))
const _0a6bcf22 = () => interopDefault(import('../pages/lawbook/details/_id.vue' /* webpackChunkName: "pages/lawbook/details/_id" */))
const _d8ace142 = () => interopDefault(import('../pages/notice/details/_code.vue' /* webpackChunkName: "pages/notice/details/_code" */))
const _21090bc0 = () => interopDefault(import('../pages/objection/details/_id.vue' /* webpackChunkName: "pages/objection/details/_id" */))
const _1503d4c6 = () => interopDefault(import('../pages/classify/_id.vue' /* webpackChunkName: "pages/classify/_id" */))
const _43f7da5f = () => interopDefault(import('../pages/contrast/_ids.vue' /* webpackChunkName: "pages/contrast/_ids" */))
const _20bd8400 = () => interopDefault(import('../pages/queryReport/_id.vue' /* webpackChunkName: "pages/queryReport/_id" */))
const _2837ed6a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aiApproximate",
    component: _a06901f0,
    name: "aiApproximate"
  }, {
    path: "/batchSearchNew",
    component: _c77a7d50,
    name: "batchSearchNew"
  }, {
    path: "/bjtest",
    component: _0acd97b1,
    name: "bjtest"
  }, {
    path: "/brandProtect",
    component: _9ce92dbc,
    name: "brandProtect"
  }, {
    path: "/businessClues",
    component: _70a84a94,
    name: "businessClues"
  }, {
    path: "/businessDevelopment",
    component: _01550b17,
    name: "businessDevelopment"
  }, {
    path: "/classify",
    component: _134ab2de,
    name: "classify"
  }, {
    path: "/commonRevision",
    component: _8f9ef500,
    name: "commonRevision"
  }, {
    path: "/customReports",
    component: _553603ca,
    name: "customReports"
  }, {
    path: "/emailModel",
    component: _9db15a78,
    name: "emailModel"
  }, {
    path: "/imgTrademarkSearch",
    component: _402d8010,
    name: "imgTrademarkSearch"
  }, {
    path: "/ipBlockade",
    component: _2ec19993,
    name: "ipBlockade"
  }, {
    path: "/lawbook",
    component: _58fe3272,
    name: "lawbook"
  }, {
    path: "/login",
    component: _2509e165,
    name: "login"
  }, {
    path: "/monitoring",
    component: _753af83c,
    name: "monitoring"
  }, {
    path: "/myClues",
    component: _6591b7ec,
    name: "myClues"
  }, {
    path: "/notice",
    component: _4aa699f2,
    name: "notice"
  }, {
    path: "/objection",
    component: _f5111c36,
    name: "objection"
  }, {
    path: "/popularize",
    component: _ac76d29c,
    name: "popularize"
  }, {
    path: "/saveUcToken",
    component: _41304cf2,
    name: "saveUcToken"
  }, {
    path: "/trademarkSearch",
    component: _7d467016,
    name: "trademarkSearch"
  }, {
    path: "/trademarkSearchInternational",
    component: _5d30b522,
    name: "trademarkSearchInternational"
  }, {
    path: "/analysis/dataExtraction",
    component: _fe719e80,
    name: "analysis-dataExtraction"
  }, {
    path: "/analysis/extension",
    component: _b52f6734,
    name: "analysis-extension"
  }, {
    path: "/analysis/newBj",
    component: _733ad8ef,
    name: "analysis-newBj"
  }, {
    path: "/analysis/portrait",
    component: _1e7f27aa,
    name: "analysis-portrait"
  }, {
    path: "/brandProtect/list",
    component: _3ea5e67e,
    name: "brandProtect-list"
  }, {
    path: "/businessClues/list",
    component: _68a6f26a,
    name: "businessClues-list"
  }, {
    path: "/classify/search",
    component: _01ca824c,
    name: "classify-search"
  }, {
    path: "/customReports/release",
    component: _4946ef02,
    name: "customReports-release"
  }, {
    path: "/lawbook/details",
    component: _4e9d863a,
    name: "lawbook-details"
  }, {
    path: "/login/binding",
    component: _3010fa50,
    name: "login-binding"
  }, {
    path: "/login/forgetPassword",
    component: _15a54da5,
    name: "login-forgetPassword"
  }, {
    path: "/login/logout",
    component: _34901387,
    name: "login-logout"
  }, {
    path: "/login/register",
    component: _7b8d1a40,
    name: "login-register"
  }, {
    path: "/monitoring/details",
    component: _6c8b9752,
    name: "monitoring-details"
  }, {
    path: "/noticecomm/searchNumber",
    component: _cf5ae2d6,
    name: "noticecomm-searchNumber"
  }, {
    path: "/objection/details",
    component: _32de1358,
    name: "objection-details"
  }, {
    path: "/special/area",
    component: _4ec41f3a,
    name: "special-area"
  }, {
    path: "/special/geography",
    component: _32b8b69f,
    name: "special-geography"
  }, {
    path: "/special/modality",
    component: _267db50e,
    name: "special-modality"
  }, {
    path: "/special/trademark",
    component: _002cfa58,
    name: "special-trademark"
  }, {
    path: "/trademarkSearch/details",
    component: _530522a5,
    name: "trademarkSearch-details"
  }, {
    path: "/trademarkSearch/tourist",
    component: _9b31b0d6,
    name: "trademarkSearch-tourist"
  }, {
    path: "/trademarkSearchInternational/data",
    component: _20039b5c,
    name: "trademarkSearchInternational-data"
  }, {
    path: "/trademarkSearchInternational/details",
    component: _e862ecc2,
    name: "trademarkSearchInternational-details"
  }, {
    path: "/user/exportManage",
    component: _cd0fd114,
    name: "user-exportManage"
  }, {
    path: "/user/notice",
    component: _b7b27f56,
    name: "user-notice"
  }, {
    path: "/user/noticeDetails",
    component: _96b06146,
    name: "user-noticeDetails"
  }, {
    path: "/user/userInfo",
    component: _5346e2d6,
    name: "user-userInfo"
  }, {
    path: "/analysis/portrait/details",
    component: _1de32dcc,
    name: "analysis-portrait-details"
  }, {
    path: "/lawbook/details/:id",
    component: _0a6bcf22,
    name: "lawbook-details-id"
  }, {
    path: "/notice/details/:code?",
    component: _d8ace142,
    name: "notice-details-code"
  }, {
    path: "/objection/details/:id",
    component: _21090bc0,
    name: "objection-details-id"
  }, {
    path: "/classify/:id",
    component: _1503d4c6,
    name: "classify-id"
  }, {
    path: "/contrast/:ids?",
    component: _43f7da5f,
    name: "contrast-ids"
  }, {
    path: "/queryReport/:id?",
    component: _20bd8400,
    name: "queryReport-id"
  }, {
    path: "/",
    component: _2837ed6a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
