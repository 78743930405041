import Vue from 'vue'
import ossClient from '../utils/aliyun.oss.client';
//全局方法和变量
const tools = {
    install(Vue) {
        Vue.prototype.tools = {
            // 游客未登录查询页面Url
            trademarkSearchTouristUrl(text, type) {
              let url = '/trademarkSearch/tourist?type=' + type;
              if (text) {
                return url + '&text=' + text
              }
              return url
            },
            // 生成商标查询url
            trademarkSearchUrl(text, type) {
                var url = '/trademarkSearch?type=' + type;
                if (text) {
                    return url + '&text=' + text
                }
                return url
            },
            //生成商标详情url//:to="tools.tmDetailUrl(scope.row.idKey)"
            // window.open(this.tools.tmDetailUrl(item.trademarkIdKey,this.searchType) ,"_blank");
            tmDetailUrl(id, type) {
                var url = '/trademarkSearch/details?id=' + id;
                if (type) {
                    url += '&type=' + type
                }
                return url
            },
            // 生成国际商标查询url
            interTrademarkSearchUrl(id) {
              var url = '/trademarkSearchInternational/details?id=' + id;
              /*if (type) {
                return url + '&text=' + text
              }*/
              return url
            },

            //生成评审文书详情url
            lawbookDetailUrl(id) {
                return '/lawbook/details/' + id;
            },
            //生成异议文书详情url
            objectionDetailUrl(id) {
                return '/objection/details/' + id;
            },
            //新开页面跳转商标查询-申请人查询
            goProposer(name, address) {
                var url = "/trademarkSearch?type=4&text=" + name;
                if (address) {
                    url += "&address=" + address;
                }
                window.open(url, "_blank");
            },
            formatDate(dateTime, pattern) {
                if (!dateTime) {
                    return '';
                }
                if (pattern == "" || pattern == null) {
                    pattern = "-";
                }

                var date = new Date(dateTime);
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                month = month < 10 ? '0' + month : month;
                var day = date.getDate();
                day = day < 10 ? '0' + day : day;
                return year + pattern + month + pattern + day;
            },
            menuData: [
                // { index: '1', name: "商标查询", url: "/trademarkSearch", icon: require('~/static/icon/search/1.png'), disabled: true, width: 16, height: 16 },
               { index: '1', name: "商标查询", url: "/trademarkSearch", icon: require('~/static/icon/search/1.png'), disabled: true, width: 16, height: 16,
                  subRoute: [
                    { index: '1-1', name: "国内商标", url: "/trademarkSearch",  disabled: true},
                    { index: '1-2', name: "批量查询", url: "/batchSearchNew", disabled: true},
                    { index: '1-3', name: "国际商标", url: "/trademarkSearchInternational", disabled: true}
                  ]
               },
              { index: '10', name: "图形查询", url: "/imgTrademarkSearch", icon: require('~/static/icon/search/imgIcon.png'), disabled: true, width: 16, height: 16 },
                { index: '2', name: "商品/服务分类", url: "/classify", icon: require('~/static/icon/search/7.png'), disabled: true, width: 15, height: 15 },
                { index: '3', name: "商标对比", url: "/contrast", icon: require('~/static/icon/search/3.png'), disabled: true, width: 13, height: 13 },
                { index: '5', name: "商标公告", url: "/notice", icon: require('~/static/icon/search/5.png'), disabled: true, width: 13, height: 13 },
                { index: '6', name: "裁定文书", url: "/rulingDocuments", icon: require('~/static/icon/search/2.png'), disabled: true, width: 12, height: 13,
                  subRoute: [
                    { index: '6-1', name: "评审文书", url: "/lawbook", icon: require('~/static/icon/search/2.png'), disabled: true, width: 12, height: 13, },
                    { index: '6-2', name: "异议文书", url: "/objection", icon: require('~/static/icon/search/8.png'), disabled: true, width: 13, height: 13, }
                  ]
                },
                /*{ index: '6', name: "评审文书", url: "/lawbook", icon: require('~/static/icon/search/2.png'), disabled: true, width: 12, height: 13 },//  /lawbook
                { index: '7', name: "异议文书", url: "/objection", icon: require('~/static/icon/search/8.png'), disabled: true, width: 13, height: 13 },//  /objection*/
                { index: '8', name: "商标监控", url: "/monitoring", icon: require('~/static/icon/search/6.png'), disabled: false, width: 13, height: 13 },//  /monitoring
                {
                    index: '4', name: "商标分析", url: "/analysis/extension", icon: require('~/static/icon/search/4.png'), disabled: true, width: 13, height: 13,
                    subRoute: [
                        // { index: '4-1', name: "数据提取", url: "/analysis/dataExtraction", icon: require('~/static/icon/search/4.png'), disabled: true, width: 13, height: 13, },
                      { index: '4-2', name: "企业商标概况", url: "/analysis/portrait", icon: require('~/static/icon/search/4.png'), disabled: true, width: 13, height: 13, },
                      { index: '4-3', name: "国内商标统计", url: "/analysis/newBj", icon: require('~/static/icon/search/4.png'), disabled: true, width: 13, height: 13, }
                    ]
                },
                // { index: '8', name: "业务开发", url: "/businessDevelopment", icon: require('~/static/icon/search/9.png'), disabled: true, width: 13, height: 13 },
                // { index: '9', name: "我的线索", url: "/myClues", icon: require('~/static/icon/search/10.png'), disabled: true, width: 13, height: 13 },
                { index: '11', name: "业务线索", url: "/businessClues", icon: require('~/static/icon/search/10.png'), disabled: true, width: 13, height: 13 },
                { index: '12', name: "品牌风险", url: "/brandProtect", icon: require('~/static/icon/search/12.png'), disabled: true, width: 13, height: 13 },
                { index: '13', name: "专项查询", url: "/special", icon: require('~/static/icon/search/9.png'), disabled: true, width: 13, height: 13,
                  subRoute: [
                    { index: '13-1', name: "地域查询", url: "/special/area", disabled: true, width: 12, height: 13, },
                    { index: '13-2', name: "驰名商标", url: "/special/trademark", disabled: true, width: 13, height: 13, },
                    { index: '13-3', name: "特殊形态", url: "/special/modality", disabled: true, width: 13, height: 13, },
                    { index: '13-4', name: "地理标志", url: "/special/geography", disabled: true, width: 13, height: 13, }
                  ]
                },
              { index: '14', name: "AI近似分析", url: "/aiApproximate", icon: require('~/static/icon/search/12.png'), disabled: true, width: 13, height: 13 },

            ],
            searchMenus: [
                { name: "近似", value: "1" },
                { name: "相同", value: "2" },
                { name: "商标号", value: "3" },
                { name: "申请人", value: "4" },
                { name: "代理机构", value: "5" },
                { name: "综合查询", value: "6" },
                { name: "超凡盲区", value: "7" },
                { name: "商标名称", value: "8"}
            ],

            /**
             * 一键粘贴
             * @param  {String} id [需要粘贴的内容]
             * @param  {String} attr [需要 copy 的属性，默认是 innerText，主要用途例如赋值 a 标签上的 href 链接]
             *
             * range + selection
             *
             * 1.创建一个 range
             * 2.把内容放入 range
             * 3.把 range 放入 selection
             *
             * 注意：参数 attr 不能是自定义属性
             * 注意：对于 user-select: none 的元素无效
             * 注意：当 id 为 false 且 attr 不会空，会直接复制 attr 的内容
             */
            copy(id, attr) {

                if (process.client) {
                    let target = null;

                    if (attr) {
                        target = document.createElement('div');
                        target.id = 'tempTarget';
                        target.style.opacity = '0';
                        if (id) {
                            let curNode = document.querySelector('#' + id);
                            target.innerText = curNode[attr];
                        } else {
                            target.innerText = attr;
                        }

                        document.body.appendChild(target);
                    } else {
                        target = document.querySelector('#' + id);
                    }

                    try {
                        let range = document.createRange();
                        range.selectNode(target);
                        window.getSelection().removeAllRanges();
                        window.getSelection().addRange(range);
                        document.execCommand('copy');
                        window.getSelection().removeAllRanges();
                        console.log("复制成功");
                        // target.parentElement.removeChild(target);
                        return true;
                    } catch (e) {
                        console.log("复制失败")
                        return false;
                    }

                }
            },
            /**
             * js 数字前面自动补零
             * @param num 补零数字
             * @param m 字符串长度
            */
            autoZero: function (num, m) {
                return (Array(m).join(0) + num).slice(-m);
            },
            /**
             * js 空值占位
             * @param val 原始值
             * @param isSeat 是否‘-’占位
            */
            emptyVal: function (val, isSeat) {
                val === '0' && (val = 0);
                return !val ? isSeat ? '-' : '' : val;
            },
            /**
             * 当前时间
             * @param val 原始值
             * @param isSeat 是否‘-’占位
            */
            getTimes: function () {
                var time = new Date();
                var h = time.getHours();
                h = h < 10 ? '0' + h : h;
                var m = time.getMinutes();
                m = m < 10 ? '0' + m : m;
                var s = time.getSeconds();
                s = m < 10 ? '0' + m : m;
                return h + ":" + m + ":" + s;
            },
            subString(str, len, spot) {
                let newLength = 0, newStr = '', singleChar;
                let chineseRegex = /[^\x00-\xff]/g;  //正则匹配
                if (str) {
                    let strLength = str.replace(chineseRegex, "**").length; //获取字符长度
                    for (let i = 0; i < str.length; i++) {
                        singleChar = str.charAt(i); //返回指定位置的字符
                        if (singleChar.match(chineseRegex) != null) { //检测是否为中文,是的话就算两个字符
                            newLength += 2;
                        } else {
                            newLength++;
                        }
                        //字符长度大于要截取的长度就跳出循环
                        if (newLength > len) break;
                        newStr += singleChar;
                    }
                    if (strLength > len) { //字符长度大于要截取的长度就省略
                        // newStr += "…";
                        spot ? newStr : newStr += "…";
                    }
                }
                return newStr;
            },
            /**
             * 判断字符串是否超出个数限制
             * @param str
             * @param num 个数
             * @returns {Boolean}
             */
            strOverstep(str, num) {
                let chineseRegex = /[^\x00-\xff]/g;  //正则匹配
                if (!str) return false
                let strLength = str.toString().replace(chineseRegex, "**").length; //获取字符长度
                return strLength > num
            },
            noticeNum(n) { // 展示最近100期的公告
                var arr = [];
                for (var i = 0; i < 100; i++) {
                    if (n < n - 100 || n <= 0) break;
                    arr[i] = n--;
                }
                return arr;
            },
            // 关闭浏览器标签页
            closePage() {
                if (navigator.userAgent.indexOf("MSIE") > 0) {
                    if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
                        window.opener = null;
                        window.close();
                    } else {
                        window.open('', '_top');
                        window.top.close();
                    }
                }
                else if (navigator.userAgent.indexOf("Firefox") > 0 || navigator.userAgent.indexOf("Chrome") > 0) {
                    window.location.href = 'about:blank';
                    window.close();
                }
                else {
                    window.opener = null;
                    window.open('', '_self');
                    window.close();
                }
            },
            toPercent(num, total) {
                return (Math.round(num / total * 10000) / 100.00 + '%');
            },
            // 去掉字符串前后所有空格：
            aroundTrim(str) {
                if (str) {
                    return str.replace(/(^\s*)|(\s*$)/g, "");
                } else {
                    return ""
                }
            },
            // 去掉字符串中所有空格(包括中间空格,需要设置第2个参数为:g)
            allTrim(str, is_global) {

                var result;

                result = str.replace(/(^\s+)|(\s+$)/g, "");

                if (is_global.toLowerCase() == "g") {

                    result = result.replace(/\s/g, "");

                }

                return result;
            }
        };
    }
}
Vue.use(tools)
// 上传图片--阿里云oss
Vue.prototype.$ossClient = ossClient;













